@import "utils/variables";
//@import "utils/helper";
@import "base/typography";
@import "base/default";



@media only screen and (max-width: #{$contentWidthBreakpoint}) /* 1400px */ {
  body{
    padding-top: 15.9rem;
    &.cms-home {
      #maincontent {
        padding-top: 15.9rem;
      }
    }
  }
  .cms-home {

    .webcrossing-base-imageslider-slider {
      ul {
        li {
          .block-title {
            &-sub {
              padding: 0 80px;
            }
          }
        }
      }
    }
  }
  .header-main {
    &-info{
      .block{
        &-title{
          padding-right: 0;
        }
      }
    }
    &-menu {
      nav {
        > ul {
          li {
            &.parent{
              a{
                &:before{
                  margin-left: 5px;
                }
              }
            }
            a {
              padding: 1.5rem;
              font-size: 1.4rem;
            }

          }
        }
      }
    }
  }
  .footer {
    &-top {
      &-container {
        ul {
          li {
            font-size: 1.3rem;
            &:before{
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #maincontent {
    .columns {
      .column {
        .products , .products-grid{
          ol {
            li{
              width: calc(50% - 7.5px);
              &:nth-of-type(3n){
                margin-right: 15px;
              }
              &:nth-of-type(2n){
                margin-right: 0;
              }
            }
          }
        }
      } /* end column */
    } /* end columns */
  } /* end maincontent */
  .cms-home {
    .webcrossing-base {
      &-category-popular {
        .block-content > {
          .image {
            width: 48%;
          }
        }
      }
      &-image-single {
        &.layout-01{
          .block-content {
            img{
            }
            .text{
              .links{
                right: inherit;
                left: 15px;
                top: calc(100% - 8rem);
              }
            }
          }
        }
      }
    }
  } /* end cms-home */
}
@media only screen and (max-width: #{$mediumContentBreakpoint}) /* 1024 */ {
  html.nav-open{
    overflow: hidden;
  }
  body.cms-home {
    #maincontent {
      padding-top: 97px;
    }
  }
  body{
    padding-top: 97px;
  }
  #maincontent .columns{
    @include flexWrap;
  }
  .sidebar{
    width: 100%;
  }
  .page-layout-2columns-left {
    &.page-with-filter{
    }
    .columns {
      .column {
        padding-left: 0;
        width: 100%;
      }
    }
  }
  .page {
    &-header {
      &.scroll{
        .header {
          &-top{
            &-logo{
              .logo{
                padding-left: 1.5rem;
              }
            }
            &-info, &-cart{
              li, .minicart-wrapper{
                > a{
                  .text{
                    display: none;
                  }
                }
              }
            }
          }
          &-main{
            &-info{
              .block-search{
                .block-title{
                  padding-bottom: 1.5rem;

                }
              }
            }
          }
          &-top {
            &-shop {
              top: 3px
            }
          }
        }
      }
    }
  }
  .header {
    &-main{
      max-height: 0;
      transition: all ease .2s;
      overflow: hidden;
      &-container{
        @include flexWrap;
        max-width: 100%;
        padding: 0;
        overflow: auto;
        max-height: calc(100vh - 100px);
      }
      &-menu{
        order: 1;
        .submenu_mobile{
          ul{
            display: flex;
            li{
              border-right: 1px solid #515151;
              border-bottom: 1px solid #515151;
              width: 100%;
              padding: 0;
              font-size: 0;
              &:last-child{
                border-right: 0;
              }
              > a{
                text-align: center;
                padding: 15px 15px;

                position: relative;
                display: inline-block;
                width: 100%;
                line-height: normal;
                &:before{
                  display: inline-block;
                  width: 100%;
                  margin-bottom: -5px;
                  max-height: 30px;
                }
              }

              &.lang{
                position: relative;
                .header-top-language{
                  padding:15px;
                  border-left: 0;
                  display: inline-block;
                  width: 100%;
                  .switcher {
                    &-label {
                      display: none;
                    }
                    .options {
                      .actions{
                        &.toogle{
                          cursor: pointer;
                          display: inline-block;
                          text-decoration: none;
                        }
                      }
                      ul.dropdown{
                        z-index: 100;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        border-left: 1px solid #515151;
                        border-right: 1px solid #515151;
                        background: $colorSecondary;
                        margin-top: 1.5rem;
                      }
                      &.active {
                        overflow: visible;
                        ul.dropdown{
                          display: block;
                        }
                        .switcher-trigger{
                          &:after{
                            transform: rotate(-180deg);
                          }
                        }
                      }
                    }
                    &-options{
                      .switcher-trigger{
                        @include flex;
                        @include flexCenter;
                        &:after{
                          content: "\f078";
                          @include fontIcon;
                          font-size: 16px;
                          margin-left: 10px;
                          transition: all ease .2s;
                          color: $colorWhite;
                        }
                      }
                      strong, li {
                        &.view {
                          &-de, &-en {
                            font-size: 1.6rem;
                            color: $colorWhite;
                            a{
                              color: $colorWhite;
                            }
                          }
                          &-de{
                            a{
                            }
                          }
                          &-en{
                            a{

                            }
                          }
                        }
                      }
                    }
                  }
                }
                a{
                  &:before{
                    display: none;
                  }
                }
              }
            }
          }
        }
        .nav-sections{
          overflow: auto;
          @include container;
          &-item-content {
            nav {
              ul{
                > li{
                  a{
                    span:not(.ui-menu-icon){
                      display: inline-block;
                    }
                  }
                }
                &.level{
                  &0{
                    padding: 0;
                    > li{
                      a{
                        padding:1rem 2rem;
                      }
                      &.parent{
                        .dropdown{
                         padding-top: 1rem;
                          padding-bottom: 1rem;
                          &:after{

                          }
                        }
                        ul{
                          position: relative !important;
                        }
                      }
                    }
                  }
                  &1{
                    padding: 0;

                    li{
                      span:not(.ui-menu-icon){

                        padding-left: 1rem;
                      }
                    }
                  }
                  &2{

                    li{
                      span:not(.ui-menu-icon){
                        padding-left: 3rem;
                      }
                    }
                  }
                  &3{

                    li{
                      span:not(.ui-menu-icon){
                        padding-left: 5rem;
                      }
                    }
                  }
                }

                li{
                  &.active{
                    background: $colorSecondary;
                    &:hover{
                      background: $colorSecondary;
                      > a{
                        background: $colorSecondary;
                      }
                    }
                  }
                  &.parent{
                    position: relative;
                    ul{
                      display: none !important;
                      left: 0 !important;
                      position: relative !important;
                      border-top: 1px solid rgba($colorWhite, 0.2);
                      &.open{
                        background: $colorPrimary;
                        display: inline-block !important;
                        @include defaultFastTrans;

                        > a, > a:hover{
                          background: $colorSecondary;
                        }
                        &:after{
                          transform: rotate(180deg);
                        }
                        > ul{

                        }
                      }
                    }
                    a{




                      &:hover{
                        background: initial;
                        ul{
                          display: none;
                        }
                      }
                    }
                    .dropdown{
                      position: absolute;
                      right: 0;
                      top: 0;
                      height: 5rem;
                      padding: 20px 20px;
                      z-index: 5;

                      &:after{
                        transition: all ease .2s;
                        content: "\f078";
                        @include fontIcon;
                        color: $colorWhite;
                        font-size: 16px;
                        display: block;
                      }
                      &.open{
                        &:after{
                          transform: rotate(-180deg);
                        }
                      }
                    }
                  }
                }
              }
              > ul {
                @include flexWrap;
                justify-content: flex-start;
                margin-right: 0;
                margin-left: 0;
                > li{
                  width: 100%;
                  @include flex;
                  align-items: center;
                  flex-wrap: wrap;
                  position: relative;
                  a{
                    text-align: left;
                    justify-content: flex-start;
                    flex-direction:row;
                    padding: 20px 15px;
                    width: calc(100% - 10rem);
                    &::before{
                      display: none;
                    }

                  }
                  &:hover{
                    > a{
                      background: $colorSecondary;
                    }
                  }
                  > ul{
                    display: none !important;
                    max-height: inherit;
                    position: relative;
                    min-width: 100%;
                    top: inherit !important;
                    left: inherit!important;

                  }
                  ul{
                    width: 100%;
                    top: 0 !important;
                    left: 0 !important;
                    li{
                      width: 100%;
                      border-bottom: 1px solid rgba($colorWhite, 0.2);
                      &:last-child{
                        border:0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-info{
        width: 100%;
        position: relative;
        order: 0;
        background: $lightGray;
        .block{
          &-search{
            @include container;
            .block-title{
              display: none;
              @include defaultFastTrans;
              strong{
                display: inline-block;
                width: auto;
                height: auto;
                line-height: normal;
                &:before{
                  content: url($imageDirResp + "icons/search_black.png");
                  display: inline-block;
                  width: auto;
                  height: auto;
                }
              }
            }
          }
          &-content{
            form{
              padding: 2rem 0;
              .search{
                input{
                  padding: 1rem 0;
                  font-size: 1.6rem;
                }
              }
              .actions{
                button{
                  padding: 1rem 2rem ;
                }
              }
            }
          }
        }
      }
    }
    &-top {
      &-logo {
        @include flex;
        justify-content: center;
        width: 100%;
        position: relative;
        .logo{
          padding-left: 1.5rem;
          width: 100%;
        }
        .nav-toggle {
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all .2s ease-in-out;
          span {
            width: 15px;
            height: 2px;
            font-size: 0;
            background: $colorSecondary;
            transition: all .2s ease-in-out;
            &:before, &:after {
              content: '';
              position: absolute;
              height: 2px;
              background: $colorSecondary;
              transition: all .2s ease-in-out;
            }
            &:before {
              width: 22px;
              transform: translateY(-8px);
            }
            &:after {
              width: 19px;
              transform: translateY(8px);
            }
          }
        }
      }
      &-shop {
        width: auto;
        position: absolute;
        right: 15px;
        top: 20px;
        @include defaultFastTrans;
        > .minicart-wrapper{
          > a{
            .counter.qty {
              .counter-number {
                right: 0;
              }
            }
            .text{
              display: none;
            }
          }
        }
        li {
          display: none;

          &:last-child {
            display: inline-block;
          }
        }
      }
      &-cart{
        .minicart-wrapper{
          a{
            padding-right: 0;
          }
        }

      }
      &-language {
          display: none;
      }

    }
  }
  .opc-wrapper, .cart-container form.form-cart, .cart-summary{
    max-width: 100%;
    width: 100%;
  }
  .cart{
    &-summary{
      margin-top: 4rem;
    }
    &-container{
      flex-direction: column-reverse;
    }
  }
  .catalog-product-view{
    .column{
      .product{
        &.media, &-info-main{
          width: 100%;
        }
      }
    }
  }
  .nav-before-open {
    .header {
      &-main {
        &-menu {
          display: inline-block;
        }
      }
    }
  }
  .nav-open {
    .page-header{
      &.scroll{
        .header {
          &-main{
            max-height: calc(100vh - 60px);
            &-container{
              max-height: calc(100vh - 60px);
            }
          }

        }
      }
    }
    .header {
        &-top {
          &-logo {
            .nav-toggle {
              span {
                transform: translateX(-50px);
                background: transparent;
                &:before {
                  transform: rotate(45deg) translate(35px, -35px);
                }
                &:after {
                  width: 22px;
                  transform: rotate(-45deg) translate(35px, 35px);
                }
              }
            }
          }
        }
        &-main{
          max-height: calc(100vh - 100px);

          &-container{

          }
          &-info{
            .block-content{
              display: inline-block;
              position: relative;
            }
          }
          &-menu{

          }
        }

    }
  }
  .cms-home {
    #maincontent {
      .columns {
        .column {

        }/* end column */
      }/* end columns */
    }/* end maincontent */
    .webcrossing-base {
      &-imageslider-slider {
        ul {
          li {
            .block-title {
              &-sub {
                text-align: center;
                .sub-headline {
                  display: none;
                }
                .text {
                  display: none;
                }
                h2 {
                  font-size: 36px;
                  line-height: normal;
                  margin: 15px 0;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  .toolbar {
    &-products {
      @include flexWrap;
      .limiter{
        width: 50%;
      }

    }
    &-sorter{
      width: 50%;
    }
    &-amount{
      width: 100%;
    }
  }
  #maincontent {
    .columns {
      .column {
        .products , .products-grid{
          ol {
            li{

            }
          }
        }

      } /* end column */
    } /* end columns */
  } /* end maincontent */
  .page-footer {
    .footer-content {
      .footer {
        &-main{
          &-container {
            .section {
              width: 100%;
              &.footer-main-menu{
                &-payment{
                  ul{
                    li{
                      width: auto;
                      img{
                        max-width: 60px;
                      }
                    }
                  }
                }
                &-service{
                  ul{
                    padding-right: 50px;
                  }
                }
                &-locations{
                  ul{
                    @include flexWrap;
                    li{
                      width: 49%;
                      margin-right: 1%;
                      margin-bottom: 20px;
                      a{
                        width: 100%;
                      }
                      &:nth-of-type(2n){
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &-top {
          .footer-top-container {
            ul {
              @include flexWrap;
              li {
                width: 100%;
                text-align: left;
                padding: 20px 0;
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  .minicart-wrapper a .counter.qty .counter-number{
    right: 0;
  }
  .checkout {
    &-index-index{
      .header{
        &-top-logo {
          .nav-toggle {
            display: none;
          }
        }
      }
    }
    &-cart-index {
      #maincontent {
        .columns {
          .column {
            .cart-container {
              @include flexWrap;
              .cart-summary, .form-cart {
                width: 100%;
              }
              .form-cart {
                padding: 20px;
                .table-wrapper {
                  table {
                    @include flexWrap;
                    thead {
                      width: 100%;
                      @include flex;
                      tr {
                        width: 100%;
                        th {
                          width: 100%;
                          display: none;

                          &.item {
                            display: inline-block;
                          }
                        }
                      }
                    }
                    tbody {
                      tr {
                        justify-content: center;
                        @include flexWrap;
                        width: 100%;
                        td.col {
                          width: 50%;
                          text-align: left;
                          padding: 15px 10px;
                          border-top: 1px solid $lightGray;

                          &:first-child {
                            border-top: 0;
                          }

                          &[data-th] {
                            &:before {
                              padding-bottom: 3px;
                              content: attr(data-th) ":";
                              display: block;
                              font-weight: 700;
                            }
                          }

                          &.item {
                            &[data-th] {
                              &:before {
                                content: "";
                              }
                            }

                            width: 100%;
                            @include flexCenter;
                            @include flexWrap;
                            position: relative;
                            padding-bottom: 0;

                            a {
                              display: inline-block;
                              width: auto;
                              text-align: left;

                              span {
                                display: inline-block;

                              }

                              .product-image-container {
                                text-align: center;
                                display: inline-block;
                              }
                            }
                          }

                          /* end Item */
                          .product-item-details {
                            max-width: calc(100% - 120px);
                            text-align: left;
                          }

                          &.subtotal {

                            width: 100%;
                            padding: 10px;
                          }

                          &.qty {
                            .qty {
                              text-align: left;
                            }
                          }
                        }

                        &.item-actions {
                          width: 100%;

                          td {
                            width: 100%;
                            padding-right: 0;

                            .actions-toolbar {
                              a {
                                &.towishlist {
                                  text-align: center;
                                  margin: 0;
                                  margin-bottom: 20px;
                                }

                                &.action-delete, &.action-edit {
                                  width: auto;
                                  padding: 15px 20px !important;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .cart {
                  a, button {
                    width: 100%;
                    margin-bottom: 10px;
                  }

                  &.main {
                    a {
                      width: 100%;
                    }
                  }
                }
              }

              /* end Form-Cart */
            }
          }
        }
      }
    }
  }
  .shipping-address-item{
    width: 49%;
    &:nth-of-type(3n){
      margin-right: 2%;
    }
    &:nth-of-type(2n){
      margin-right: 0;
    }
  }
  .opc-sidebar{
    width: 100%;
  }
  .page-with-filter{
    .column{
      order:1;
    }
  }
}
@media only screen and (max-width:  #{$mediumSmallBreakpoint}) /* 768 */ {
  .block-minicart {
    min-width: 310px;
    .block-content {
      > .actions {
        @include flexWrap;
        .actions, .secondary{
          width: 100%;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .header{
    &-top{
      &-info, &-cart{
        li, .minicart-wrapper{
          > a{
            .text{
              display: none;
            }
          }
        }
      }
      &-cart{
        .minicart-wrapper a{
          padding-right: 2rem;
        }
      }
    }
  }
  .page-main {
    .actions-toolbar{
      flex-wrap: wrap;
      > *{
        width: 100%;
        button{
          width: 100%;
          margin-bottom: 8px;
        }
      }
    }
  }
  #maincontent {
    .columns {
      @include flexWrap;
      flex-direction: column;
      .sidebar{
        width: 100%;
        background: $lightGray;
        margin-top: 50px;
        padding: 20px;
        ul{
          li{
            a, strong{
              padding: 7px 0;
              display: inline-block;
              width: 100%;
            }
          }
        }
      } /* end sidebar */
      .column {
        width: 100%;
        padding: 0;
        .products , .products-grid{
          ol {
            li{

            }
          }
        }
        .toolbar-products {
          .limiter {
            width: auto;
            border:0;
            padding-right: 0;
          }
        }
      } /* end column */
    } /* end columns */
  } /* end maincontent */
  .customer-account-create {
    form {
      > fieldset {
        width: 100%;
      }
    }
  }
  .customer-account-login {
    #maincontent {
      .columns {
        .column {
          .login-container {
            @include flexWrap;
            > div{
              width: 100%;
              margin-bottom: 80px;
            }
          }
        }/* end column */
      }/* end columns */
    }/* end maincontent */
  }/* end customer-account-login */
  .page-layout-2columns-left{
    #maincontent {
      .columns {
        .column{
          width: 100%;
          padding: 0;
        } /* end column */
      } /* end columns */
    } /* end maincontent */
  }
  .cms-home {
    .webcrossing-base{
      &-imageslider-slider {
        ul {
          li {
            .block-title {
              &-sub {
                h2{
                  font-size: 26px;
                }
              }
            }
          }
        }
      }
    }
    #maincontent {
      .columns {
        .column {
          .webcrossing-base {
            &-image-single {
              &.layout-01{
                .block-content {
                  img{
                  }
                  .text{
                    .links{


                    }
                  }
                }
              }
              &.layout-02 {
                padding: 0;
                .block-content {
                  .text {
                    padding: 60px 20px;
                  }
                }
              }
            }
            &-product-slider {
              .splide__track{
                padding: 0;
                padding-bottom: 73px;
                box-sizing: border-box;
              }
              .block-title {

              }
              .splide__arrows {
                position: absolute;
                bottom: 0;
                width: 100%;
                @include flexCenter;
                button.splide__arrow {
                  &--prev, &--next {
                    position: relative;
                    left: inherit;
                    right: inherit;
                    top: inherit;
                    bottom: inherit;
                    height: auto;
                    text-align: center;
                    width: 30%;
                  }
                }
              }
            }

            &-formular-newsletter {
              padding: 35px 20px 55px;
              .block-content {
                .form {
                  @include flexWrap;
                  .field {
                    &.newsletter {
                    }
                  }
                  .actions{
                    width: 100%;
                    button{
                      border: 2px solid  $colorBlack;
                      border-top: 0;
                      padding: 20px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .catalog-product-view{
    #maincontent {
      .columns {
        .column {
          .product {
            &.media {
              width: 100%;
            }
            &-info-main{
              padding-top: 50px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .page-main .actions-toolbar > .primary{
    margin-right: 0;
  }
  .checkout-index-index {
    #maincontent {
      .columns {
        .column {
          .checkout-container {
            .opc-wrapper, aside {
              width: 100%;
              padding-right: 0;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .page-footer {
    .footer-content {
      .footer {
        &-top {
          &-container {
            padding: 0 20px;
          }
        }
        &-main{
          &-container{
            padding: 50px 20px;
            .section{
              &.footer-main-menu{
                &-locations{
                  ul{
                    li{
                      width: 100%;
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .contact-index-index {
    .webcrossing-base-map-text-single {
      .block-content {
        @include flexWrap;
        .text{
          min-height: inherit;
        }
        .map{
          min-height: 300px;
        }
        >*{
          width: 100%;
        }
      }
    }
  }
  .column{
    table {
      display: flex;
      flex-wrap: wrap;

      thead {
        width: 100%;
      }

      tr {
        @include flex;
        flex-wrap: wrap;
        &.item-info{
          position: relative;
          justify-content: space-between;
        }
        th {
          display: none;

        }

        td {
          text-align: left;
          &.amount{
            width: 100%;
            @include flex;
            &[data-th] {
              &:before {
                content: attr(data-th) ":";
                width: 100%;

                padding: 0 2rem 1rem 0;
                padding-right: 0;
                text-align: left;
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
              }
            }
            span{
              &.price{
                justify-content: flex-end;
              }
              width: 100%;
              @include flex;
              &[data-th] {
                &:before {
                  content: attr(data-th) ":";
                  width: 100%;

                  padding: 0 2rem 1rem 0;
                  padding-right: 0;
                  text-align: left;
                  font-size: 1.5rem;
                  font-weight: bold;
                  text-transform: uppercase;
                  display: block;
                }
              }
            }
          }

          &.col[data-th] {
            &:before {
              content: attr(data-th) ":";
              width: 100%;

              padding: 0 2rem 1rem 0;
              padding-right: 0;
              text-align: left;
              font-size: 1.5rem;
              font-weight: bold;
              text-transform: uppercase;
              display: block;
            }
          }

          &.col {
            width: auto;
            text-align: left;
            padding-top: 0;
            padding-bottom: 3rem;
            padding-right: 1rem;

            display: block;
            > * {
              margin-top: 1rem;
              display: block;
            }

            &.subtotal {
              padding-right: 0;
            }
            &.price{
              padding-left: 13rem;
            }
            &.action{
              position: absolute;
              padding: 0;
              top: 5rem;
              right: 0;
              margin-right: 0;
            }
            &.item {
              width: 100%;
              padding-right: 0;

              > a {
                width: auto;
                display: inline-block;
              }

              .product {
                &-item {
                  &-details {
                    display: inline-block;
                    vertical-align: top;
                  }
                }
              }
            }
          }

        }
      }
    }
  }
  .account{
    .table{
      &-wrapper{
        &.order-items{
          &.ordered{
            .order{
              &-title{
                margin-bottom: 0;
              }
            }
          }
          table{
            tbody{
              padding: 0;
            }
            tr {

              th{
                display: none;
              }
              td {
                width: 100%;
                padding: .5rem 0;
                @include flex;
                align-items: flex-start;
                > * {
                  margin-top: 0;
                }
                &.col{

                }
                &.price {
                  padding-left: inherit;
                  > span{
                    display: inline-block;
                    width: auto;
                  }
                }
              }
            }
            tfoot{
              width: 100%;
              tr{
                padding: .5rem 0;
                td{
                  padding: 0;
                  &:before{
                    padding-bottom: 0;
                  }
                }
              }

            }
          }

        }
        table{
          thead{
            display: none;
          }
          tbody{
            border-bottom: 0;
          }
          tr{
            padding-top: 2rem;
          }
          td{
            &.col{
              padding-left: 0;
              padding-bottom: 1rem;
              &[data-th]::before{
                padding-bottom: 0;
              }
              > * {
                margin-top: 0;
                display: inline-block;
              }
            }
          }
        }

      }
    }
    table{
      width: 100%;
      tr{
        display: block;
        padding-bottom: 2rem ;
        th{
          display: none;
        }
        td{
          display: block;
          padding: 5px 10px;
          &.col{
            &[data-th]::before{
              width: auto;
              display: inline-block;
              margin-right: 1rem;
            }
          }
          &.actions{
            justify-content: flex-start;
            a{
              font-weight: bold;
              &:before{
                display: none;
              }
              &.edit{
                margin-left: 1rem;
                color: $colorPrimary;
              }
              &.delete{
                color: $colorRed;
              }
              span{
                width: auto;
                height: auto;
                display: inline-block;
                font-size: 1.6rem;
                position: relative;
                overflow: initial;
              }
            }
          }
          &:before{
            font-weight: bold;
            content:attr(data-th) ': ';
          }

        }
      }
    }
  }
  .shipping-address-item{
    width: 100%;
    margin-right: 0;
    &:nth-of-type(3n){
      margin-right: 0;
    }
  }
  .step-content table {
    &.table-checkout-shipping-method {
      td.col {
        width: auto;
        text-align: left;
        @include flex;
        padding-bottom: 0;
      > *{
        margin-top: 0;
      }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .page-wrapper{
    header {
      .header-main {
        &-info {
          .block-search {
            .block-content {
              form {
                @include flexWrap;
                padding-top: 10px;
                padding-bottom: 20px;
                .search {
                  width: 100%;
                  margin: 0;
                  input {
                    border-bottom: 0;
                  }
                }
                .actions{
                  width: 100%;
                }
              }
            }
          }
        }/* end -INFO */
      }
    } /* end HEADER */
    #maincontent {
      .columns {
        margin-top: 5rem;
        .column {
          .products , .products-grid{
            ol {
              li{
                width: 100%;
              }
            }
          }
        } /* end column */
      } /* end columns */
    } /* end maincontent */
  } /* end .page-wrapper */
  .breadcrumbs ul{
    flex-wrap: wrap;
    li{
      padding-right: .3rem;
      padding-left: 0;
      padding-top: 0;
      &:last-child{
        padding-right: 0;
      }
      a, strong{
        font-size: 1.2rem;
        &:after{
          margin-left: .3rem;
        }
      }
      &.home{

        a{
          font-size: 0;
          &:before{
            display: inline-block;
            font-size: 12px;
            @include fontIcon;
            vertical-align: middle;
            content: "\f015";
          }
        }

      }
    }
  }
  .header-top {
    &-info{
      width: 74%;
    }
    &-logo{
      .logo{
        img{
          max-width: 100%;
        }
      }
    }

    &-info, &-cart {
      li, .minicart-wrapper {
        > a {
          padding: 0 .7rem 0 0;
          &:before{
            margin-left: 0;
          }
        }
      }
    }
  }
  .cms-home {
    #maincontent {
      .columns {
        margin-top: 0;
        .column {
          .webcrossing-base {
            &-category-popular {
              .block-content {
                > .image {
                  width: 100%;
                  margin: 10px 0;
                }
              }
            }
          }
        }
      }
    }
    .webcrossing-base-imageslider-slider {
      .splide__arrow{
        top: 28%;
      }
      ul li {
        background: $lightGray;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: auto;
        }

        .block-title {
          position: relative;
          height: 100%;
          padding-top: calc(260.44 / 570.34 * 100%);
          padding-bottom: 1.5rem;
          @include flexCenter;

          &-sub {
            padding: 0 1rem;
            max-width: 100%;

            .link {
              margin-top: 0;
              padding:.8rem 1.5rem;
              line-height: normal;
            }

            h2 {
              color: $colorBlack;
              font-size: 2rem;
            }

          }
        }
      }
    }
  }
  .page-footer {
    .footer {
      &-content {
        .footer {
          &-main{
            &-container{
              .section{
                &.footer{
                  &-main-menu-payment{
                    ul{
                      li{
                        width: 14%;
                      }
                    }
                  }
                }
              }
            }
          }
          &-bottom {
            .footer-bottom-navigation {
              ul {
                li {
                  a {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  .catalog-product-view .column .product-info-main .page-title{
    font-size: 3rem;
  }
}

